import { AK } from "../constants/AppKeys";
import axios from "axios";
import {
  getUserdata,
  validateToken,
  getBrowser,
  AxiosError,
} from "./sessionexpiry";
import { useNavigate } from "react-router-dom";
import { handleGetCartInfoStorageItems } from "./cartManager";
import { APPENUMS } from "../constants/AppEnums";

let bower = {};
let ipinfo = {};

export const AxiosIPINFO = async () => {
  return axios
    .get(AK.IPINFOURL)
    .then((res) => {
      return res;
    })
    .catch(() => {});
};

const callpageinit = async () => {
  bower = getBrowser();
  ipinfo = await AxiosIPINFO();
};
callpageinit();

export const AxiosPost = async (apiname, payload, checktoken = true) => {
  let token = checktoken ? validateToken() : null;

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;
  return axios
    .post(AK.APIURL + apiname, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .catch((error) => {
      return AxiosError(error);
    });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosPost_Nocatch = async (
  apiname,
  payload,
  checktoken = true
) => {
  let token = checktoken ? validateToken() : null;

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;
  return axios.post(AK.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

const generateHash = async (message) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

function convertToUserAgent(clientData) {
  const browserName = clientData.browser.name;
  const browserVersion = clientData.browser.version;
  const osName = clientData.os.name;
  const osVersion = clientData.os.version;
  const platformType = clientData.platform.type;

  // Creating the user agent string
  const userAgent = `Mozilla/5.0 (${osName} ${osVersion}; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) ${browserName}/${browserVersion} Safari/537.36`;

  return userAgent;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function getfbp() {
  if (window.location.href.indexOf("fbclid") > 0) {
    var fbp = document?.cookies?._fbp;
    return fbp;
    // var ua = window.navigator.userAgent;
    // history.pushState(
    //   {},
    //   null,
    //   queryString + "&fbclid=" + fbclick_final + "&fbp=" + fbp + "ua" + ua
    // );
  } else {
    return null;
  }
}

function getfbclid() {
  if (window.location.href.indexOf("fbclid") > 0) {
    const currentTime = Math.floor(Date.now() / 1000); // Optional: Convert to seconds if needed
    const fbclid = getParameterByName("fbclid", window.location.href);

    return "fb.1." + currentTime + "." + fbclid;
  } else {
    return null;
  }
}

function bkpgetfbclid() {
  if (window.location.href.indexOf("fbclid") > 0) {
    const currentTime = Math.floor(Date.now() / 1000); // Optional: Convert to seconds if needed
    const fbclid = getParameterByName("fbclid", window.location.href);

    // Construct the object and convert it to a JSON string
    console.log(
      JSON.stringify({ fbclid: "fb.1." + currentTime + "." + fbclid })
    );
    const queryString = window.location.search;
    var cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.split("="))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {}
      );
    var fbclick = cookies._fbc;
    var fbclick_final = fbclick?.slice(19);
    var fbp = cookies._fbp;
    var ua = window.navigator.userAgent;
    // history.pushState(
    //   {},
    //   null,
    //   queryString + "&fbclid=" + fbclick_final + "&fbp=" + fbp + "ua" + ua
    // );
  } else {
    return null;
  }
}

export const FBConversionPUSH = async (event) => {
  // let token = checktoken ? validateToken() : null;
  let email = "";
  let phone = "";
  let client_ip_address = "";
  let client_user_agent = "";
  let fname = "";
  let lname = "";
  let gender = "";
  let dob = "";
  let city = "";
  let state = "";
  let zip = "";
  let country = "";
  let external_id = "";

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
  };

  const payload = {
    email: getUserdata()?.email,
    phone: getUserdata()?.mobile,
    event_name: event?.name,
    client_ip_address: ipinfo?.data?.ip,
    client_user_agent: JSON.stringify(bower),
    lname: getUserdata()?.name,
    // "gender": "male",
    // "dob": "1990-01-01",
    // "city": "Mumbai",
    // "state": "MH",
    // "zip": "400001",
    // "country": "IN",

    // External ID for the user
    //  "external_id": "12345",
    // Facebook click ID for tracking
    // "fbp": "fb.1.1596403881668.1116446470",

    // // Facebook browser cookie for tracking
    // "fbc": "fb.1.1558571054389.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",

    // Subscription ID for the user
    // "subscription_id": "sub_12345",

    // Lead ID for the user
    // "lead_id": "lead_123111233345",

    // URL of the event source
    //  "event_source_url": "https://example.com/cart",

    // Order ID associated with the event
    //  "orderid": "order_12345"
  };

  // payload['storeid'] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  // "email": "test@example.com",
  //   "phone": "+1234567890",
  //     "event_name": "AddToCart",
  //       "client_ip_address": "106.197.111.215",
  //         "client_user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.111 Safari/537.36"

  const utcTimestampInSeconds = Math.floor(Date.now() / 1000);
  let cartdata = {};

  cartdata = await handleGetCartInfoStorageItems((data) => {
    // console.log(data);
    // console.log(data?.products?.length);
  });

  let data = [];

  if (cartdata?.products?.length === 0) {
    data.push({
      event_name: event?.name,
      event_time: utcTimestampInSeconds,

      event_id: event?.id,
      action_source: "website",
      opt_out: false,
      user_data: {
        em: [await generateHash(getUserdata()?.email)],
        ph: [await generateHash(getUserdata()?.mobile)],
        client_ip_address: ipinfo?.data?.ip,
        client_user_agent: convertToUserAgent(bower),
        fbc: getfbclid(),
        fbp: getfbp(),
      },
      data_processing_options: ["LDU"],
      data_processing_options_country: 1,
      data_processing_options_state: 1000,
    });
  } else {
    data = await Promise.all(
      cartdata?.products?.map(async (_cartdata) => ({
        event_name: event?.name,
        event_time: utcTimestampInSeconds,

        event_id: event?.id,
        action_source: "website",
        opt_out: false,
        user_data: {
          em: [await generateHash(getUserdata()?.email)],
          ph: [await generateHash(getUserdata()?.mobile)],
          client_ip_address: ipinfo?.data?.ip,
          client_user_agent: convertToUserAgent(bower),
          fbc: getfbclid(),
          fbp: getfbp(),
        },
        custom_data: {
          currency: "INR",
          value: cartdata?.total,
          content_name: _cartdata?.category_name,
          content_category: _cartdata?.category_name,
          content_ids: [_cartdata?.esin],
          contents: [
            {
              id: _cartdata?.esin,
              quantity: _cartdata?.cartquantity,
              item_price: _cartdata?.sp,
            },
          ],
          content_type: "product",
          order_id: "",
          num_items: 1,
          search_string: _cartdata?.name,
          status: "completed",
          delivery_category: "in_store",
        },
        data_processing_options: ["LDU"],
        data_processing_options_country: 1,
        data_processing_options_state: 1000,
      })) || []
    );
    // cartdata?.products?.map(async _cartdata => {
    //   data.push({
    //     event_name: event_name,
    //     event_time: utcTimestampInSeconds,
    //     event_id: "event1234",
    //     action_source: "website",
    //     opt_out: false,
    //     user_data: {
    //       em: [await generateHash(getUserdata()?.email)],
    //       ph: [await generateHash(getUserdata()?.mobile)],
    //     },
    //     custom_data: {
    //       currency: "INR",
    //       value: cartdata?.total,
    //       content_name: _cartdata?.category_name,
    //       content_category: _cartdata?.category_name,
    //       content_ids: [_cartdata?.esin],
    //       contents: [
    //         {
    //           id: _cartdata?.esin,
    //           quantity: _cartdata?.cartquantity,
    //           item_price: _cartdata?.sp,
    //         },
    //       ],
    //       content_type: "product",
    //       order_id: "",
    //       num_items: 1,
    //       search_string: _cartdata?.name,
    //       status: "completed",
    //       delivery_category: "in_store",
    //     },
    //     data_processing_options: ["LDU"],
    //     data_processing_options_country: 1,
    //     data_processing_options_state: 1000,
    //   })
    // });
  }

  // console.log(cartdata);
  const raw = JSON.stringify({
    data: data,
  });

  return axios
    .post(AK.APIURL + AK.FBCONVERSIONPUSHAPI, raw, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {});
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const axiosSetWishlist = async (id, navigate) => {
  let token = true ? validateToken() : null;

  if (token === null) {
    navigate("/signin");
  }

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    productid: id.toString(),
  };

  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  return axios
    .post(AK.APIURL + AK.WISHLISTPUSHAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {});
};

export const axiosGetWishlist = async (setproductwishlistData) => {
  let token = true ? validateToken() : null;

  if (token !== null) {
    const headers = {
      accesskey: AK.ACCESSKEY,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const payload = {};

    payload["storeid"] = process.env.REACT_APP_STORE_ID;
    payload["storecode"] = process.env.REACT_APP_STORE_CODE;

    return axios
      .post(AK.APIURL + AK.WISHLISTFETCHAPI, payload, {
        headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((res) => {
        console.log(res);
        setproductwishlistData(res?.data?.wishlist);
        return res?.data?.wishlist;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const axiosGetOverallProductRatings = async (
  setproductoverallratingData
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  const payload = {};

  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  return axios
    .post(AK.APIURL + AK.OVERALLPRODUCTSRATINGAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      // console.log(res)
      // setproductwishlistData(res?.data?.wishlist)
      setproductoverallratingData(res?.data?.products);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const axiosGetProductReviews = async (
  id,
  setproductaveragerating,
  setproductreviewList
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  const payload = {
    productid: id,
  };

  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  return axios
    .post(AK.APIURL + AK.PRODUCTREVIEWSFETCHAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      console.log(res);
      setproductaveragerating(res?.data?.average_rating);
      setproductreviewList(res?.data?.reviews);
      // setproductwishlistData(res?.data?.wishlist)
    })
    .catch((error) => {});
};

export const axiosSetProductReviews = async (id, rating, comment, files) => {
  let token = true ? validateToken() : null;

  if (token !== null) {
    const headers = {
      accesskey: AK.ACCESSKEY,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("storeid", process.env.REACT_APP_STORE_ID);
    formData.append("storecode", process.env.REACT_APP_STORE_CODE);
    formData.append("productid", id);
    formData.append("comment", comment);
    if (files?.length > 0)
      files.forEach((file, index) => {
        formData.append(`files[]`, file);
      });

    return axios
      .post(AK.APIURL + AK.PRODUCTREVIEWSPUSHAPI, formData, {
        headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((res) => {
        console.log(res);
        // setproductaveragerating(res?.data?.average_rating)
        // setproductreviewList(res?.data?.reviews)
        // setproductwishlistData(res?.data?.wishlist)
      })
      .catch((error) => {});
  }
};

export const axiosClearWishlist = async (id) => {
  let token = true ? validateToken() : null;

  if (token !== null) {
    const headers = {
      accesskey: AK.ACCESSKEY,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const payload = {
      productid: id.toString(),
    };

    payload["storeid"] = process.env.REACT_APP_STORE_ID;
    payload["storecode"] = process.env.REACT_APP_STORE_CODE;

    return axios
      .post(AK.APIURL + AK.WISHLISTCLEARAPI, payload, {
        headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {});
  }
};

export const AxiosDirectPost = async (apiurl, payload, checktoken = true) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.post(apiurl, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosInstamojoDirectPost = async (
  apiurl,
  payload,
  checktoken = true
) => {
  const headers = {
    "X-Api-Key": "test_a15356a96ae27ef4d454f525d56",
    "X-Auth-Token": "test_a3ea1ff9524f246b516b0a9ea36",
  };

  return axios.post(apiurl, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AK.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxioseGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosMockupGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AK.MOCKUPSURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const ReddisAxiosGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AK.REDDISAPIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosFilePost = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  let token = checktoken ? validateToken(history) : null;
  const headers = {
    accesskey: AK.ACCESSKEY,
    accesstoken: token,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  };

  return await axios.post(AK.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};