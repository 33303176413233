import React from "react";

export function VideoAlbum(props) {
    const {
        url,
        comment
    } = props;
    const imgData = [
        {
            id: 0,
            url: url,
            title: comment,
            isShowing: false
        },
        // {
        //     id: 1,
        //     url: 'https://images.unsplash.com/photo-1467269204594-9661b134dd2b?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
        //     title: 'European village',
        //     isShowing: false
        // },
        // {
        //     id: 2,
        //     url: 'https://images.unsplash.com/photo-1438955224980-4f333574560d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
        //     title: 'Portland, OR',
        //     isShowing: false
        // },
    ];
    const [images, loadImages] = React.useState(imgData);
    const [imageID, setImageID] = React.useState(null);
    const [imageObj, getImageObj] = React.useState(images[imageID]);

    React.useEffect(() => {
        getImageObj(images[imageID])
    })

    function clickHandler(event) {
        setImageID(event.target.id)
    }
    console.log(imageObj)
    return (


        imageObj === undefined ?
            <ImgList images={images}
                clickHandler={clickHandler}
                imageID={imageID} />
            :
            <ImageModal imageObj={imageObj}
                setImageID={setImageID} />


    )
}


function ImgList({ images, imageID, clickHandler }) {
    return images.map(image => {
        // return <video
        //     key={image.id}
        //     className={imageID === image.id ? "img-modal" : "myImg"}
        //     id={image.id}
        //     src={image.url}
        //     title={image.title}
        //     alt={image.title}
        //     onClick={() => clickHandler(event)}
        // />
        console.log(image.url)
        return <video key={image.id}
            className={imageID === image.id ? "img-modal" : "myImg"}
            id={image.id}
            src={image.url}
            title={image.title}
            alt={image.title}
            onClick={() => clickHandler(event)} controls autoplay>
            <source key={image.id}
                className={imageID === image.id ? "img-modal" : "myImg"}
                id={image.id}
                src={image.url}
                title={image.title}
                alt={image.title}
                onClick={() => clickHandler(event)} type="video/mp4" />

        </video>
    })
}

function ImageModal({ imageObj, setImageID }) {
    return (
        <div id="myModal" className="img-modal">

            <span className="x-close"
                onClick={() => setImageID(null)}>&times;</span>

            {/* <video className="modal-content" src={imageObj.url} /> */}

            <video className="modal-content" style={{ height: "60%" }} controls autoplay>
                <source src={imageObj.url} type="video/mp4" />

            </video>

            <div id="caption">{imageObj.title}</div>
        </div>
    )
}