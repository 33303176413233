
import React, { useState } from 'react';
import "./customstyle.css";

function CheckBoxPage(props) {
  const {
    userData,
    cartinfoData,
    isCheckedWallet,
    setIsCheckedWallet,
    shippingCalc,
    getfinalamount
  } = props;

  const [walletbalance, setWalletbalance] = useState(0);
  // const [isCheckedWallet, setIsCheckedWallet] = useState(false);
  function handleCheckboxChange() {
    setIsCheckedWallet(prevChecked => !prevChecked);
    if (!isCheckedWallet) {
      setWalletbalance(userData?.wallet - (getfinalamount()));
    } else {
      setWalletbalance(0);
    }
  }

  return (
    <div style={{
      display: userData === null ? "none" : "flex"
    }} className="walletbalanceMSG2  justify-content-between">
      <p className="walletbalanceMSG3 d-flex pt-1">
        <input type="checkbox" id="myCheckbox" onChange={handleCheckboxChange} checked={isCheckedWallet} />
        <label for="myCheckbox"></label>&nbsp;&nbsp;Use ThaiThingal Cash (₹{userData?.wallet})</p>
      {isCheckedWallet ? <>
        <b className="mt-1"><span >Balance : {isNaN(walletbalance) || walletbalance < 0 ? 0 : walletbalance}</span></b>
      </> : <></>}


    </div>
  );
}

export default CheckBoxPage;