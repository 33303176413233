import React, { useState, useRef, useCallback } from "react";
import {
  handleProductInfoAddtoCart,
  handleSetCartInfoStorageItems,
} from "../utilities/cartManager";
import { FaExclamationCircle } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { PhotoAlbum } from "./PhotoAlbum";
import { VideoAlbum } from "./VideoAlbum";
import { AxiosPost_Nocatch, FBConversionPUSH } from "../utilities/axioscall";
import { AK } from "../constants/AppKeys";
import { APPENUMS } from "../constants/AppEnums";

export const userCartInfoFetch = async (
  cartinfoData,
  setCartInfoData,
  push = false,
  fetch = false
) => {
  //console.log(cartinfoData)
  if (push) {
    AxiosPost_Nocatch(AK.USERCARTINFOFETCHPUSHAPI, cartinfoData, true)
      .then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          console.log(res.data);
          // setCartInfoData(res.data);
          // setCount({ ...count, count: count + 1 });
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  } else if (fetch) {
    AxiosPost_Nocatch(AK.USERCARTINFOAPI, {}, true)
      .then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          setCartInfoData(res?.data?.cartinfo);
          handleSetCartInfoStorageItems(res?.data?.cartinfo);
          setCount({ ...count, count: count + 1 });
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
};

export const addtocartclicks = async (
  event,
  productinfo,
  sizeListData,
  setchooseSizeError,
  chooseCoupleSizeError,
  setchooseCoupleSizeError,
  pselectedMenSizes,
  psetselectedMenSizes,
  pselectedWomenSizes,
  psetselectedWomenSizes,
  pselectedCoupleSizes,
  psetselectedCoupleSizes,
  notify,
  notifyerror,
  setCount,
  count,
  pselectedSizes,
  pselectedWeights,
  weightListData,
  setchooseWeightError,
  gravingText,
  setgravingTextError,
  navigate,
  setCartInfoData,
  cartinfoData
) => {
  try {
    if (productinfo?.addtocart) {
      navigate("/cart");
      return;
    }
    // console.log(productinfo);

    if (productinfo?.variation?.includes("2")) {
      let sizeselected = false;
      sizeListData.map((sizesub) => {
        if (sizesub["selected"]) sizeselected = true;
      });
      if (!sizeselected) {
        setchooseSizeError(true);
        notifyerror("Please choose size.");
        setCount({ ...count, count: count + 1 });

        return;
      }
      setchooseSizeError(false);
      setCount({ ...count, count: count + 1 });
      productinfo["addtocart"] = true;

      // productinfo['selectedSizes'] = removeDuplicates(productinfo['selectedSizes']);

      // productinfo['selectedSizes'].map(async Sizecode => {
      let newproductinfo = {};
      console.log(pselectedSizes);
      newproductinfo["imageurl"] = productinfo["imageurl"];
      newproductinfo["id"] = productinfo["id"];
      newproductinfo["esin"] = productinfo["esin"];
      newproductinfo["storeid"] = productinfo["storeid"];
      newproductinfo["products_id"] = productinfo["products_id"];
      newproductinfo["category_id"] = productinfo["category_id"];
      newproductinfo["size_id"] = productinfo["size_id"];
      newproductinfo["colorcodes_id"] = productinfo["colorcodes_id"];
      newproductinfo["weight_id"] = productinfo["weight_id"];
      newproductinfo["quantity"] = productinfo["quantity"];
      newproductinfo["mrp"] = productinfo["mrp"];
      newproductinfo["sp"] = productinfo["sp"];
      newproductinfo["sortorder"] = productinfo["sortorder"];
      newproductinfo["city_shipping"] = productinfo["city_shipping"];
      newproductinfo["state_shipping"] = productinfo["state_shipping"];
      newproductinfo["national_shipping"] = productinfo["national_shipping"];
      newproductinfo["delivery_time"] = productinfo["delivery_time"];
      newproductinfo["status"] = productinfo["status"];
      newproductinfo["is_featured"] = productinfo["is_featured"];
      newproductinfo["visibility"] = productinfo["visibility"];
      newproductinfo["customizable"] = productinfo["customizable"];
      newproductinfo["variation"] = productinfo["variation"];
      newproductinfo["name"] = productinfo["name"];
      newproductinfo["eng_name"] = productinfo["eng_name"];
      newproductinfo["sku"] = productinfo["sku"];
      newproductinfo["seo_url"] = productinfo["seo_url"];
      newproductinfo["weight"] = productinfo["weight"];
      newproductinfo["images"] = productinfo["images"];
      newproductinfo["addtocart"] = productinfo["addtocart"];
      newproductinfo["selectedSizes"] = pselectedSizes;
      newproductinfo["selectedSize"] = pselectedSizes;
      newproductinfo["Variation"] = "Size";
      newproductinfo["selectedVariation"] = pselectedSizes?.code;
      newproductinfo["cartquantity"] = productinfo["cartquantity"];

      await handleProductInfoAddtoCart(
        newproductinfo,
        setCartInfoData,
        setCount,
        cartinfoData,
        count
      );
      // })
    } else if (productinfo?.variation?.includes("3")) {
      let weightselected = false;
      weightListData.map((weightsub) => {
        if (weightsub["selected"]) weightselected = true;
      });

      if (!weightselected) {
        setchooseWeightError(true);
        notifyerror("Please choose weight.");
        setCount({ ...count, count: count + 1 });
        return;
      }
      setchooseWeightError(false);
      setCount({ ...count, count: count + 1 });
      productinfo["addtocart"] = true;

      // productinfo['selectedWeights'] = removeDuplicates(productinfo['selectedWeights']);

      // productinfo['selectedWeights'].map(async Weightcode => {
      let newproductinfo = {};

      newproductinfo["imageurl"] = productinfo["imageurl"];
      newproductinfo["id"] = productinfo["id"];
      newproductinfo["esin"] = productinfo["esin"];
      newproductinfo["storeid"] = productinfo["storeid"];
      newproductinfo["products_id"] = productinfo["products_id"];
      newproductinfo["category_id"] = productinfo["category_id"];
      newproductinfo["size_id"] = productinfo["size_id"];
      newproductinfo["colorcodes_id"] = productinfo["colorcodes_id"];
      newproductinfo["weight_id"] = productinfo["weight_id"];
      newproductinfo["quantity"] = productinfo["quantity"];
      newproductinfo["mrp"] = productinfo["mrp"];
      newproductinfo["sp"] = productinfo["sp"];
      newproductinfo["sortorder"] = productinfo["sortorder"];
      newproductinfo["city_shipping"] = productinfo["city_shipping"];
      newproductinfo["state_shipping"] = productinfo["state_shipping"];
      newproductinfo["national_shipping"] = productinfo["national_shipping"];
      newproductinfo["delivery_time"] = productinfo["delivery_time"];
      newproductinfo["status"] = productinfo["status"];
      newproductinfo["is_featured"] = productinfo["is_featured"];
      newproductinfo["visibility"] = productinfo["visibility"];
      newproductinfo["customizable"] = productinfo["customizable"];
      newproductinfo["variation"] = productinfo["variation"];
      newproductinfo["name"] = productinfo["name"];
      newproductinfo["eng_name"] = productinfo["eng_name"];
      newproductinfo["sku"] = productinfo["sku"];
      newproductinfo["seo_url"] = productinfo["seo_url"];
      newproductinfo["weight"] = productinfo["weight"];
      newproductinfo["images"] = productinfo["images"];
      newproductinfo["addtocart"] = productinfo["addtocart"];
      newproductinfo["selectedWeights"] = productinfo["selectedWeights"];
      newproductinfo["selectedWeight"] = pselectedWeights;
      newproductinfo["Variation"] = "Weight";
      newproductinfo["selectedVariation"] = pselectedWeights?.code;
      newproductinfo["cartquantity"] = productinfo["cartquantity"];

      await handleProductInfoAddtoCart(
        newproductinfo,
        setCartInfoData,
        setCount,
        cartinfoData,
        count
      );
      // })
    } else if (productinfo?.variation?.includes("4")) {
      if (gravingText === "") {
        setgravingTextError(true);
        notifyerror("Please type your graving text.");
        setCount({ ...count, count: count + 1 });
        eventtarget.readOnly = false;
        return;
      }
      setgravingTextError(false);
      setCount({ ...count, count: count + 1 });
      productinfo["addtocart"] = true;

      // productinfo['selectedWeights'] = removeDuplicates(productinfo['selectedWeights']);

      let newproductinfo = {};

      newproductinfo["imageurl"] = productinfo["imageurl"];
      newproductinfo["id"] = productinfo["id"];
      newproductinfo["esin"] = productinfo["esin"];
      newproductinfo["storeid"] = productinfo["storeid"];
      newproductinfo["products_id"] = productinfo["products_id"];
      newproductinfo["category_id"] = productinfo["category_id"];
      newproductinfo["size_id"] = productinfo["size_id"];
      newproductinfo["colorcodes_id"] = productinfo["colorcodes_id"];
      newproductinfo["weight_id"] = productinfo["weight_id"];
      newproductinfo["quantity"] = productinfo["quantity"];
      newproductinfo["mrp"] = productinfo["mrp"];
      newproductinfo["sp"] = productinfo["sp"];
      newproductinfo["sortorder"] = productinfo["sortorder"];
      newproductinfo["city_shipping"] = productinfo["city_shipping"];
      newproductinfo["state_shipping"] = productinfo["state_shipping"];
      newproductinfo["national_shipping"] = productinfo["national_shipping"];
      newproductinfo["delivery_time"] = productinfo["delivery_time"];
      newproductinfo["status"] = productinfo["status"];
      newproductinfo["is_featured"] = productinfo["is_featured"];
      newproductinfo["visibility"] = productinfo["visibility"];
      newproductinfo["customizable"] = productinfo["customizable"];
      newproductinfo["variation"] = productinfo["variation"];
      newproductinfo["name"] = productinfo["name"];
      newproductinfo["eng_name"] = productinfo["eng_name"];
      newproductinfo["sku"] = productinfo["sku"];
      newproductinfo["seo_url"] = productinfo["seo_url"];
      newproductinfo["weight"] = productinfo["weight"];
      newproductinfo["images"] = productinfo["images"];
      newproductinfo["addtocart"] = productinfo["addtocart"];
      newproductinfo["selectedGravingText"] = gravingText;
      newproductinfo["Variation"] = "Custom Text";
      newproductinfo["selectedVariation"] = gravingText;
      newproductinfo["cartquantity"] = productinfo["cartquantity"];

      await handleProductInfoAddtoCart(
        newproductinfo,
        setCartInfoData,
        setCount,
        cartinfoData,
        count
      );
    } else if (productinfo?.variation?.includes("7")) {
      let sizeselected = false;
      sizeListData.map((sizesub) => {
        if (sizesub["selected"]) sizeselected = true;
      });
      if (productinfo["selectedCoupleSizes"]?.Men[0]?.code === undefined) {
        setchooseSizeError(true);
        notifyerror("Please choose Men size.");
        setCount({ ...count, count: count + 1 });

        return;
      }

      if (productinfo["selectedCoupleSizes"]?.Women[0]?.code === undefined) {
        setchooseSizeError(true);
        notifyerror("Please choose Women size.");
        setCount({ ...count, count: count + 1 });

        return;
      }
      setchooseSizeError(false);
      setCount({ ...count, count: count + 1 });
      productinfo["addtocart"] = true;

      console.log(productinfo);
      // productinfo['selectedSizes'] = removeDuplicates(productinfo['selectedSizes']);

      // productinfo['selectedSizes'].map(async Sizecode => {
      let newproductinfo = {};
      console.log(pselectedSizes);
      newproductinfo["imageurl"] = productinfo["imageurl"];
      newproductinfo["id"] = productinfo["id"];
      newproductinfo["esin"] = productinfo["esin"];
      newproductinfo["storeid"] = productinfo["storeid"];
      newproductinfo["products_id"] = productinfo["products_id"];
      newproductinfo["category_id"] = productinfo["category_id"];
      newproductinfo["size_id"] = productinfo["size_id"];
      newproductinfo["colorcodes_id"] = productinfo["colorcodes_id"];
      newproductinfo["weight_id"] = productinfo["weight_id"];
      newproductinfo["quantity"] = productinfo["quantity"];
      newproductinfo["mrp"] = productinfo["mrp"];
      newproductinfo["sp"] = productinfo["sp"];
      newproductinfo["sortorder"] = productinfo["sortorder"];
      newproductinfo["city_shipping"] = productinfo["city_shipping"];
      newproductinfo["state_shipping"] = productinfo["state_shipping"];
      newproductinfo["national_shipping"] = productinfo["national_shipping"];
      newproductinfo["delivery_time"] = productinfo["delivery_time"];
      newproductinfo["status"] = productinfo["status"];
      newproductinfo["is_featured"] = productinfo["is_featured"];
      newproductinfo["visibility"] = productinfo["visibility"];
      newproductinfo["customizable"] = productinfo["customizable"];
      newproductinfo["variation"] = productinfo["variation"];
      newproductinfo["name"] = productinfo["name"];
      newproductinfo["eng_name"] = productinfo["eng_name"];
      newproductinfo["sku"] = productinfo["sku"];
      newproductinfo["seo_url"] = productinfo["seo_url"];
      newproductinfo["weight"] = productinfo["weight"];
      newproductinfo["images"] = productinfo["images"];
      newproductinfo["addtocart"] = productinfo["addtocart"];
      newproductinfo["selectedSizes"] = pselectedSizes;
      newproductinfo["selectedSize"] = pselectedSizes;
      newproductinfo["selectedMenSize"] =
        productinfo["selectedCoupleSizes"]?.Men[0];
      newproductinfo["selectedWomenSize"] =
        productinfo["selectedCoupleSizes"]?.Women[0];
      newproductinfo["Variation"] = "Couple Size";
      newproductinfo[
        "selectedVariation"
      ] = ` Men: ${productinfo["selectedCoupleSizes"]?.Men[0]?.code} Women: ${productinfo["selectedCoupleSizes"]?.Women[0]?.code}`; //pselectedSizes?.code;
      newproductinfo["cartquantity"] = productinfo["cartquantity"];

      await handleProductInfoAddtoCart(
        newproductinfo,
        setCartInfoData,
        setCount,
        cartinfoData,
        count
      );
      // })
    } else {
      setCount({ ...count, count: count + 1 });
      productinfo["addtocart"] = true;
      await handleProductInfoAddtoCart(
        productinfo,
        setCartInfoData,
        setCount,
        cartinfoData,
        count
      );
    }

    userCartInfoFetch(cartinfoData, setCartInfoData, true, false);
    FBConversionPUSH(APPENUMS.EVENT_ENUMS.ADD_TO_CART);
    notify();
    //eventtarget.readOnly = false;
  } catch (err) {
    console.log(err);
  }
};

export function variationsRender(
  variationsListData,
  productinfo,
  colorcodesListData,
  filterColor,
  onchangeProductColor,
  setCount,
  count,
  sizeListData,
  filterSize,
  pselectedSizes,
  psetselectedSizes,
  chooseCoupleSizeError,
  setchooseCoupleSizeError,
  pselectedMenSizes,
  psetselectedMenSizes,
  pselectedWomenSizes,
  psetselectedWomenSizes,
  pselectedCoupleSizes,
  psetselectedCoupleSizes,
  cartinfoData,
  setProductInfoData,
  setproductinfoselectedSize,
  productinfoselectedSize,
  chooseSizeError,
  weightListData,
  filteWeight,
  pselectedWeights,
  psetselectedWeights,
  setproductinfoselectedWeight,
  productinfoselectedWeight,
  onchangeProductWeight,
  chooseWeightError,
  setgravingText,
  chooseGravingTextError
) {
  return (
    <div>
      {filterSize}
      {variationsListData?.map((variations) =>
        productinfo?.variation?.includes(variations?.id.toString()) &&
        variations?.id === 1 ? (
          <>
            <div className="block mb-2">
              <div>
                <span className="block mb-2 colorName">COLOR OPTIONS</span>

                <div className="flex flex-wrap">
                  {colorcodesListData.filter(filterColor).map((color) => (
                    <button
                      className={
                        color?.selected === true
                          ? "mr-4 mb-2 rounded-full border-blue-300 text-blue  border-2 hover:border-gray-900 p-1"
                          : "mr-4 mb-2 rounded-full border border-black-300 p-1"
                      }
                      onClick={async (event) => {
                        event.preventDefault();
                        let eventtarget = event.currentTarget;
                        if (eventtarget.readOnly) return;
                        eventtarget.readOnly = true;
                        colorcodesListData.map((colorsub) => {
                          colorsub["selected"] = false;
                        });
                        onchangeProductColor(color);
                        color["selected"] =
                          color?.selected === true ? false : true;
                        setCount({ ...count, count: count + 1 });
                        eventtarget.readOnly = false;
                      }}
                    >
                      <div
                        className="rounded-full bg-orange-300 w-5 h-5"
                        style={{ backgroundColor: color.code }}
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : productinfo?.variation?.includes(variations?.id.toString()) &&
          variations?.id === 2 ? (
          <>
            <div className="block mb-2">
              <div>
                <span className="block mb-2 selectSizeTitle">SELECT SIZE</span>
                {sizeListData
                  .filter((size) => filterSize(size, null))
                  .map((size) => (
                    <button
                      key={size.code}
                      id={"sizecode" + size.code}
                      className={
                        size?.code === pselectedSizes?.code
                          ? "mb-2 mr-2 selectSizeBtn border-blue-300 text-blue  border-2 hover:border-gray-900 rounded-md"
                          : "mb-2 mr-2 selectSizeBtn border hover:border-2 hover:border-gray-400 rounded-md"
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        let eventtarget = event.currentTarget;
                        if (eventtarget.readOnly) return;
                        eventtarget.readOnly = true;
                        psetselectedSizes(size);
                        setCount({ ...count, count: count + 1 });
                        const updatedSizeListData = sizeListData.map(
                          (sizesub) => {
                            if (sizesub?.code === size?.code) {
                              sizesub.selected = true;
                            } else {
                              sizesub.selected = false;
                            }
                            return sizesub;
                          }
                        );
                        const selectedSizes = updatedSizeListData
                          ?.filter((sizesub) => sizesub.selected)
                          ?.map((sizesub) => sizesub?.code);
                        const productsaddtocart =
                          cartinfoData?.products?.filter((prod) => {
                            return (
                              prod?.selectedSize?.code === size?.code &&
                              prod?.id === productinfo?.id
                            );
                          });
                        if (productsaddtocart?.length === 0)
                          productinfo["addtocart"] = false;
                        else productinfo["addtocart"] = true;

                        setProductInfoData({
                          ...productinfo,
                          selectedSizes: selectedSizes,
                        });
                        setproductinfoselectedSize(productinfoselectedSize);
                        console.log(cartinfoData?.products);
                        eventtarget.readOnly = false;
                        // console.log(selectedSizes)
                        // console.log(productinfoselectedSize)
                      }}
                    >
                      {size.code}
                    </button>
                  ))}

                <div hidden={!chooseSizeError}>
                  <p className="flex mb-2 text-red-500">
                    <span style={{ paddingTop: "3px" }}>
                      <FaExclamationCircle color="#e10e0e" />
                    </span>
                    &nbsp;&nbsp;Please choose size as per your fit.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : productinfo?.variation?.includes(variations?.id.toString()) &&
          variations?.id === 3 ? (
          <>
            <div className="block mb-2">
              <div>
                <span className="block mb-2 selectSizeTitle">
                  SELECT WEIGHT
                </span>
                {/* Mobile */}
                {weightListData?.filter(filteWeight).map((weight) => (
                  <button
                    key={weight.code}
                    id={"weightcode" + weight.code}
                    className={
                      weight?.code === pselectedWeights?.code
                        ? "mb-2 mr-2 selectSizeBtn border-blue-300 text-blue border-2 hover:border-gray-900 rounded-md"
                        : "mb-2 mr-2 selectSizeBtn border hover:border-gray-400 rounded-md"
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      let eventtarget = event.currentTarget;
                      if (eventtarget.readOnly) return;
                      eventtarget.readOnly = true;
                      psetselectedWeights(weight);
                      const updatedWeightListData = weightListData.map(
                        (weightsub) => {
                          if (weightsub.code === weight.code) {
                            weightsub.selected = !weightsub.selected;
                          }
                          return weightsub;
                        }
                      );
                      const selectedWeights = updatedWeightListData
                        .filter((weightsub) => weightsub.selected)
                        .map((weightsub) => weightsub.code);
                      const productsaddtocart = cartinfoData?.products?.filter(
                        (prod) => {
                          return (
                            prod?.selectedWeight === weight?.code &&
                            prod?.id === productinfo?.id
                          );
                        }
                      );

                      if (productsaddtocart?.length === 0)
                        productinfo["addtocart"] = false;
                      else productinfo["addtocart"] = true;

                      setProductInfoData({
                        ...productinfo,
                        selectedWeights: selectedWeights,
                      });
                      setproductinfoselectedWeight(productinfoselectedWeight);
                      onchangeProductWeight(weight);
                      eventtarget.readOnly = false;
                    }}
                  >
                    {weight.code}
                  </button>
                ))}

                <div hidden={!chooseWeightError}>
                  <p className="flex mb-2 text-red-500">
                    <span style={{ paddingTop: "3px" }}>
                      <FaExclamationCircle color="#e10e0e" />
                    </span>
                    &nbsp;&nbsp;Please choose weight as per your fit.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : productinfo?.variation?.includes(variations?.id.toString()) &&
          variations?.id === 4 ? (
          <>
            <div className="block mb-2">
              <div>
                {/* <span className="block mb-2 selectSizeTitle">
                              Custom Text
                            </span> */}

                <input
                  class="w-full mb-4 px-12 py-2 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  onChange={(e) => setgravingText(e.target.value)}
                  type="text"
                  placeholder="CUSTOM TEXT"
                ></input>

                <div hidden={!chooseGravingTextError}>
                  <p className="flex mb-2 text-red-500">
                    <span style={{ paddingTop: "3px" }}>
                      <FaExclamationCircle color="#e10e0e" />
                    </span>
                    &nbsp;&nbsp;Please type your graving text.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : productinfo?.variation?.includes(variations?.id.toString()) &&
          variations?.id === 7 ? (
          <>
            <div className="block mb-2">
              <div>
                <span className="block mb-2 selectSizeTitle">
                  SELECT MULTIPLE SIZE
                </span>
                <span className="block mb-2 selectSizeTitle">MEN</span>
                {sizeListData
                  .filter((size) => filterSize(size, "Men"))
                  .map((size) => (
                    <button
                      key={size.id}
                      id={"sizecode" + size.id}
                      className={
                        size?.id === pselectedMenSizes?.id
                          ? "mb-2 mr-2 selectSizeBtn border-blue-300 text-blue  border-2 hover:border-gray-900 rounded-md"
                          : "mb-2 mr-2 selectSizeBtn border hover:border-2 hover:border-gray-400 rounded-md"
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        let eventtarget = event.currentTarget;
                        if (eventtarget.readOnly) return;
                        eventtarget.readOnly = true;
                        psetselectedMenSizes(size);
                        setCount({ ...count, count: count + 1 });
                        const updatedSizeListData = sizeListData
                          .filter((size) => filterSize(size, "Men"))
                          .map((sizesub) => {
                            if (sizesub?.id === size?.id) {
                              sizesub.selected = true;
                            } else {
                              sizesub.selected = false;
                            }
                            return sizesub;
                          });
                        const selectedSizes = updatedSizeListData
                          ?.filter((sizesub) => sizesub.selected)
                          ?.map((sizesub) => sizesub?.id);

                        const currentMenSizes = sizeListData.filter(
                          (selectedSize) =>
                            selectedSize.name === "Men" &&
                            selectedSize.selected === true
                        );
                        const currentWomenSizes = sizeListData.filter(
                          (selectedSize) =>
                            selectedSize.name === "Women" &&
                            selectedSize.selected === true
                        );
                        //` Men: ${productinfo["selectedCoupleSizes"]?.Men[0]?.code} Women: ${productinfo["selectedCoupleSizes"]?.Women[0]?.code}`
                        const productsaddtocart =
                          cartinfoData?.products?.filter((prod) => {
                            return (
                              prod["selectedMenSize"]?.id === size?.id &&
                              currentWomenSizes[0]?.id ===
                                prod["selectedWomenSize"]?.id &&
                              prod?.id === productinfo?.id
                            );
                          });
                        if (productsaddtocart?.length === 0)
                          productinfo["addtocart"] = false;
                        else productinfo["addtocart"] = true;

                        setProductInfoData({
                          ...productinfo,
                          selectedSizes: selectedSizes,
                          selectedCoupleSizes: {
                            Men: currentMenSizes,
                            Women: currentWomenSizes,
                          },
                        });
                        setproductinfoselectedSize(productinfoselectedSize);
                        console.log(cartinfoData?.products);
                        eventtarget.readOnly = false;
                        // console.log(selectedSizes)
                        // console.log(productinfoselectedSize)
                      }}
                    >
                      {size.code}
                    </button>
                  ))}

                <span className="block mb-2 selectSizeTitle">WOMEN</span>
                {sizeListData
                  .filter((size) => filterSize(size, "Women"))
                  .map((size) => (
                    <button
                      key={size.id}
                      id={"sizecode" + size.id}
                      className={
                        size?.id === pselectedWomenSizes?.id
                          ? "mb-2 mr-2 selectSizeBtn border-blue-300 text-blue  border-2 hover:border-gray-900 rounded-md"
                          : "mb-2 mr-2 selectSizeBtn border hover:border-2 hover:border-gray-400 rounded-md"
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        let eventtarget = event.currentTarget;
                        if (eventtarget.readOnly) return;
                        eventtarget.readOnly = true;
                        psetselectedWomenSizes(size);
                        setCount({ ...count, count: count + 1 });
                        const updatedSizeListData = sizeListData
                          .filter((size) => filterSize(size, "Women"))
                          .map((sizesub) => {
                            if (sizesub?.id === size?.id) {
                              sizesub.selected = true;
                            } else {
                              sizesub.selected = false;
                            }
                            return sizesub;
                          });

                        const currentMenSizes = sizeListData.filter(
                          (selectedSize) =>
                            selectedSize.name === "Men" &&
                            selectedSize.selected === true
                        );
                        const currentWomenSizes = sizeListData.filter(
                          (selectedSize) =>
                            selectedSize.name === "Women" &&
                            selectedSize.selected === true
                        );

                        const selectedSizes = updatedSizeListData
                          ?.filter((sizesub) => sizesub.selected)
                          ?.map((sizesub) => sizesub?.id);
                        const productsaddtocart =
                          cartinfoData?.products?.filter((prod) => {
                            return (
                              prod["selectedWomenSize"]?.id === size?.id &&
                              currentMenSizes[0]?.id ===
                                prod["selectedMenSize"]?.id &&
                              prod?.id === productinfo?.id
                            );
                          });
                        if (productsaddtocart?.length === 0)
                          productinfo["addtocart"] = false;
                        else productinfo["addtocart"] = true;

                        // setProductInfoData({
                        //   ...productinfo,
                        //   selectedSizes: selectedSizes,
                        // });
                        setProductInfoData({
                          ...productinfo,
                          selectedSizes: selectedSizes,
                          selectedCoupleSizes: {
                            Men: currentMenSizes,
                            Women: currentWomenSizes,
                          },
                        });
                        setproductinfoselectedSize(productinfoselectedSize);
                        console.log(cartinfoData?.products);
                        eventtarget.readOnly = false;
                        // console.log(selectedSizes)
                        // console.log(productinfoselectedSize)
                      }}
                    >
                      {size.code}
                    </button>
                  ))}

                <div hidden={!chooseSizeError}>
                  <p className="flex mb-2 text-red-500">
                    <span style={{ paddingTop: "3px" }}>
                      <FaExclamationCircle color="#e10e0e" />
                    </span>
                    &nbsp;&nbsp;Please choose couple size as per your fit.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )
      )}
    </div>
  );
}

export function productreviewRender(productreviewList, setwritereview, assets) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 course-details-content">
          <div className="course-details-card mt--40">
            <div className="course-content">
              <h5 className="mb--20">Review</h5>
              <div className="row row--30 flex flex-wrap">
                <div className="col-lg-4 w-1/4">
                  <div className="rating-box">
                    <div className="rating-number">5.0</div>
                    <div className="rating mb-2">
                      <StarRatings
                        rating={5.0}
                        starRatedColor="orange"
                        starDimension="20px"
                        starSpacing="2px"
                      />
                    </div>
                    <span>({productreviewList.length} Review)</span>{" "}
                    <center>
                      <div
                        className="rateBtn"
                        onClick={() => setwritereview(true)}
                      >
                        RATE
                      </div>
                    </center>{" "}
                  </div>
                </div>
                <div className="col-lg-8 w-2/3">
                  <div className="review-wrapper">
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        5 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating === 5
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter((prod) => prod.rating >= 5)
                            .length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        4 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 4 && prod.rating < 5
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 4 && prod.rating < 5
                          ).length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        3 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 3 && prod.rating < 4
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 3 && prod.rating < 5
                          ).length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        2 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 2 && prod.rating < 3
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 2 && prod.rating < 3
                          ).length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        1 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList?.filter(
                                (prod) => prod.rating >= 1 && prod.rating < 2
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="80"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 1 && prod.rating < 2
                          ).length
                        }
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                hidden={productreviewList.length === 0}
                className="comment-wrapper pt--40"
              >
                <div className="section-title">
                  <h5 className="mb--25">Reviews</h5>
                </div>

                {productreviewList.map((prod) => (
                  <>
                    <div className="edu-comment">
                      <div className="thumbnail">
                        {" "}
                        <img
                          src={`/yofte-assets/users/user1.webp`}
                          alt="Comment Images"
                        />{" "}
                      </div>
                      <div className="comment-content">
                        <div className="comment-top">
                          <h6 className="title">{prod.username}</h6>
                          <div className="rating mb-4">
                            <StarRatings
                              rating={prod.rating}
                              starRatedColor="orange"
                              starDimension="20px"
                              starSpacing="2px"
                            />
                          </div>
                        </div>
                        {/* <span className="subtitle">
                    “ Outstanding Review Design ”
                  </span> */}
                        <p>{prod.comment}</p>
                        <br />
                        <div style={{ display: "flex" }}>
                          {prod?.media
                            ?.slice(1, -1)
                            ?.split(",")
                            .map(
                              (media) =>
                                media.trim().slice(1, -1) && (
                                  <>
                                    {media?.includes(".mp4") ? (
                                      <VideoAlbum
                                        comment={prod.comment}
                                        url={assets + media.trim().slice(1, -1)}
                                      ></VideoAlbum>
                                    ) : (
                                      // <video src={assets + media.trim().slice(1, -1)} style={{ width: "120px", height: "120px" }} />

                                      // <img src={assets + media.trim().slice(1, -1)} style={{ width: "120px", height: "120px" }} />

                                      <PhotoAlbum
                                        comment={prod.comment}
                                        url={assets + media.trim().slice(1, -1)}
                                      ></PhotoAlbum>
                                    )}

                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  </>
                                )
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function mobileproductreviewRender(
  productreviewList,
  setwritereview,
  assets
) {
  return (
    <div className="">
      <div className="row p-0">
        <div className="col-md-8 course-details-content ">
          <div className="course-details-card ">
            <div className="course-content">
              <h5 className="mb--20">Review</h5>
              <div className="row row--30 flex flex-wrap">
                <div className="col-lg-4 w-full">
                  <div className="rating-box">
                    <div className="rating-number">5.0</div>
                    <div className="rating mb-2">
                      <StarRatings
                        rating={5.0}
                        starRatedColor="orange"
                        starDimension="20px"
                        starSpacing="2px"
                      />
                    </div>
                    <span>({productreviewList.length} Review)</span>{" "}
                    <center>
                      <div
                        className="rateBtn"
                        onClick={() => setwritereview(true)}
                      >
                        RATE
                      </div>
                    </center>
                  </div>
                </div>
                <div className="col-lg-8 w-full">
                  <div className="review-wrapper">
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        5 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating === 5
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter((prod) => prod.rating >= 5)
                            .length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        4 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 4 && prod.rating < 5
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 4 && prod.rating < 5
                          ).length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        3 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 3 && prod.rating < 4
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 3 && prod.rating < 5
                          ).length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        2 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 2 && prod.rating < 3
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 2 && prod.rating < 3
                          ).length
                        }
                      </span>{" "}
                    </div>
                    <div className="single-progress-bar">
                      <div className="rating-text">
                        {" "}
                        1 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (productreviewList.filter(
                                (prod) => prod.rating >= 1 && prod.rating < 2
                              ).length /
                                productreviewList?.length) *
                              100
                            }%`,
                          }}
                          aria-valuenow="0"
                          aria-valuemin="80"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span className="rating-value">
                        {
                          productreviewList.filter(
                            (prod) => prod.rating >= 1 && prod.rating < 2
                          ).length
                        }
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                hidden={productreviewList.length === 0}
                className="comment-wrapper pt--40"
              >
                <div className="section-title">
                  <h5 className="mb--25">Reviews</h5>
                </div>

                {productreviewList.map((prod) => (
                  <>
                    <div className="edu-comment">
                      <div className="thumbnail">
                        {" "}
                        <img
                          src={`/yofte-assets/users/user1.webp`}
                          alt="Comment Images"
                        />{" "}
                      </div>
                      <div className="comment-content">
                        <div className="comment-top">
                          <h6 className="title">{prod.username}</h6>
                          <div className="rating mb-4">
                            <StarRatings
                              rating={prod.rating}
                              starRatedColor="orange"
                              starDimension="20px"
                              starSpacing="2px"
                            />
                          </div>
                        </div>
                        {/* <span className="subtitle">
                    “ Outstanding Review Design ”
                  </span> */}
                        <p>{prod.comment}</p>
                        <br />
                        <div style={{ display: "flex" }}>
                          {prod?.media
                            ?.slice(1, -1)
                            ?.split(",")
                            .map(
                              (media) =>
                                media.trim().slice(1, -1) && (
                                  <>
                                    {media?.includes(".mp4") ? (
                                      <VideoAlbum
                                        comment={prod.comment}
                                        url={assets + media.trim().slice(1, -1)}
                                      ></VideoAlbum>
                                    ) : (
                                      // <video src={assets + media.trim().slice(1, -1)} style={{ width: "120px", height: "120px" }} />

                                      // <img src={assets + media.trim().slice(1, -1)} style={{ width: "120px", height: "120px" }} />

                                      <PhotoAlbum
                                        comment={prod.comment}
                                        url={assets + media.trim().slice(1, -1)}
                                      ></PhotoAlbum>
                                    )}

                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  </>
                                )
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function writeareviewRender(
  productname,
  imgurl,
  fileInputRef,
  uploadSingleFiles,
  triggerFileInput,
  singleFile,
  singleFilenameList,
  removeImage,
  rating,
  setrating,
  ratingError,
  setratingError,
  comment,
  setcomment,
  commentError,
  setcommentError,
  handleaddReview,
  setwritereview,
  reviewloader,
  setreviewloader
) {
  return (
    <div id="app">
      <div>
        <div className="sideNavBox">
          <div className="verify-otp-popup" />
          <header
            className="mHeaderDiv mHeaderSticky visible-sm visible-xs"
            style={{ zIndex: 9999 }}
          >
            <div className="noMg mHeader">
              <div className="backToPreviousPage">
                <a
                  className="backPreviousCat"
                  onClick={() => setwritereview(false)}
                >
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/ic-web-head-primary-back.svg"
                    alt="back"
                  />
                </a>
              </div>
              {/* <div className="iconMenuOption">
                                <span style={{ width: "auto" }}>
                                    <form className="msearchContainer" style={{ display: "inline-block" }}>
                                        <label>
                                            <img
                                                src="https://images..com/web/ic-web-head-search.svg"
                                                className="header-icon ml-1 ico-search"
                                                alt="search-icon"
                                            />
                                        </label>
                                    </form>
                                </span>
                                <span>
                                    <div>
                                        <img
                                            src="https://images..com/web/ic-web-head-wishlist.svg"
                                            className="header-icon"
                                            alt="wish-list"
                                        />
                                    </div>
                                </span>
                                <a href="/cart">
                                    <span>
                                        <img
                                            src="https://images..com/web/ic-web-head-cart.svg"
                                            className="header-icon"
                                            alt="shopping-bag"
                                        />
                                        <span className="cartCount cartCountHome">5</span>
                                    </span>
                                </a>
                            </div> */}
            </div>
          </header>
          <div className="mMenuOverlay undefined" />
        </div>
        <div
          hidden={!reviewloader}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "1111",
            background: "white",
          }}
        >
          <center>
            <br />
            <br />

            <br />
            <br />
            <img
              style={{ width: "150px" }}
              src={`../yofte-assets/loader/loadingstars.gif`}
              alt={`rating loader`}
            />

            <br />
            <br />
            <br />
            <br />
          </center>
        </div>
        <div className="rev-wrpr">
          <ul className="listContainer ctr">
            <li className="rev">
              <div className="rate-rev-wrpr">
                <div className="wrpr">
                  <div className="prod-detail-wrpr">
                    <img className="prod-img" src={imgurl} alt={productname} />
                    <div className="prod-detail">
                      <span className="manufacturer pd-btm">TamilTshirts</span>
                      <div className="prod-name pd-btm">
                        <div className="name-wrpr">{productname}</div>
                      </div>
                      <div className="sub-rtg-wrpr">
                        {[...Array(5)].map((_, index) => (
                          <div className="parent" key={index}>
                            <img
                              className={
                                index < rating
                                  ? "sub-rtng-star"
                                  : "sub-rtng-star fade"
                              }
                              src={
                                index < rating
                                  ? rating === 1 && index === 0
                                    ? "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/group-3x-1614948060.webp"
                                    : rating === 2 && index === 1
                                    ? "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/022-sleepy-3x-1614948060.webp"
                                    : rating === 3 && index === 2
                                    ? "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/008-shock-3x-1614948058.webp"
                                    : rating === 4 && index === 3
                                    ? "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/010-laugh-1-3x-1614948058.webp"
                                    : rating === 5 && index === 4
                                    ? "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/014-kiss-3x-1614948059.webp"
                                    : "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/mask-3x-1614947766.webp"
                                  : "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/mask-3x-1614947766.webp"
                              }
                              alt="Rating Star"
                              onClick={() => setrating(index + 1)}
                              onMouseOver={() => setrating(index + 1)}
                            />
                            {index + 1 <= rating && (
                              <img
                                className="effect"
                                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/mask-3x-1614947766.webp"
                                alt="Rating Star"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      {ratingError && (
                        <p className="text-red-500">Rate the stars required.</p>
                      )}
                      {/* <div className="sub-rtg-wrpr">

                                                {[...Array(5)].map((_, index) => (
                                                    <div className="parent" key={index}>
                                                        <img
                                                            className="sub-rtng-star"
                                                            src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/mask-3x-1614947766.webp"
                                                            alt="Rating Star"
                                                        />
                                                    </div>
                                                ))}
                                                <div className="parent">
                                                    <img
                                                        className="sub-rtng-star sel-icon animate"
                                                        src="https://images..com/web/014-kiss-3x-1614948059.png"
                                                        alt="Rating Star"
                                                    />
                                                    <img
                                                        className="effect"
                                                        src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/mask-3x-1614947766.webp"
                                                        alt="Rating Star"
                                                    />
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    {singleFile.length !== 0 &&
                      singleFile.map((url, index) => (
                        <div key={url}>
                          <div className="img-block bg-gray">
                            {singleFilenameList[index].includes(".mp4") ? (
                              <video
                                className="img-fluid2"
                                src={url}
                                alt="..."
                              />
                            ) : (
                              <img className="img-fluid2" src={url} alt="..." />
                            )}
                            {/* <img className="img-fluid2" src={url} alt="..." /> */}
                            <span
                              className="remove_img"
                              onClick={() => removeImage(index)}
                            >
                              X
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="rev-wrapper">
                    <textarea
                      className="rev-box"
                      placeholder="Yayy! That’s how we roll. Tell us what you liked the most."
                      onChange={(e) => setcomment(e.target.value)}
                    />
                    {commentError && (
                      <p className="text-red-500">Minimum 10 Characters</p>
                    )}

                    {/* <p className="input-wrng">Minimum 10 Characters</p> */}
                    <div className="img-upload-wrpr">
                      <div className="camera-img" onClick={triggerFileInput}>
                        <img
                          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/001-camera-3x-1616393588.webp"
                          alt="camera image"
                        />
                        <input
                          type="file"
                          accept=".jpg,.png,.webp,.jpeg,.mp4"
                          name="img-upload"
                          style={{ display: "none" }}
                          onChange={uploadSingleFiles}
                          ref={fileInputRef}
                        />
                      </div>
                      <div className="img-txt">
                        <span>Add a few shots & show off your style.</span>
                        <span className="file-size">
                          Maximum File Size: 10 Mb
                        </span>
                      </div>
                    </div>
                    <div
                      className="undefined button iconDefault"
                      style={{
                        backgroundColor: "rgb(66, 162, 162)",
                        padding: 20,
                        display: "inline-block",
                        color: "rgb(255, 255, 255)",
                        cursor: "pointer",
                        width: "100%",
                        fontSize: "0.9rem",
                        lineHeight: 0,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid rgb(66, 162, 162)",
                        zoom: 1,
                        userSelect: "none",
                        borderRadius: 5,
                      }}
                      onClick={async () => {
                        var error = false;
                        if (comment === "") {
                          error = true;
                          setcommentError(true);
                        } else setcommentError(false);

                        if (rating === 0) {
                          error = true;
                          setratingError(true);
                        } else setratingError(false);

                        if (error) return;
                        setreviewloader(true);
                        await handleaddReview();
                        setreviewloader(false);
                      }}
                    >
                      <span className="btn-txt">Submit</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}