import { axiosClearWishlist, axiosSetWishlist } from "../utilities/axioscall";

export const addtowishlist = async (product, count, setCount, productwishlistData, setproductwishlistData, navigate) => {

    if (!productwishlistData?.includes(product?.id?.toString()?.toString())) {
        product['wishlist'] = true;
        let _productwishlistData = productwishlistData;
        _productwishlistData?.push(product?.id?.toString());
        setproductwishlistData(_productwishlistData);
        axiosSetWishlist(product?.id?.toString(), navigate);
        setCount({
            ...count,
            count: count + 1,
        });

    } else {
        let _productwishlistData = productwishlistData;
        const index = _productwishlistData?.indexOf(product?.id?.toString());
        if (index > -1) { // only splice array when item is found
            _productwishlistData?.splice(index, 1); // 2nd parameter means remove one item only
        }
        setproductwishlistData(_productwishlistData);
        axiosClearWishlist(product?.id?.toString());
        setCount({
            ...count,
            count: count + 1,
        });
    }
}