const STOREID = process.env.REACT_APP_STORE_ID;
const STORECODE = process.env.REACT_APP_STORE_CODE;

const generateApiEndpoint = (storeId, jsonName) => {
  return `redisapi/readData.php?storeid=${storeId}&jsonname=${jsonName}`;
};

const generateECOMSAASEndpoint = (storeCode, jsonName) => {
  const formattedDate = new Date().getTime();
  return `${process.env.REACT_APP_ECOMSAASASSETS}redisapi/${
    storeCode === "" ? "" : storeCode + "/"
  }${jsonName}.json?version=${formattedDate}`;
};
//https://assetsvilva.blr1.cdn.digitaloceanspaces.com/ecomsaas/redisapi/orderstatus.json

export const AK = Object.freeze({
  ACCESSKEY: "VilvaKart2023",
  STOREID: STOREID,

  IPINFOURL: process.env.REACT_APP_IPINFOURL,
  APIURL: process.env.REACT_APP_APIURL, //"https://earth.haodapay.com/",//https://earth.mypayhaoda.com/
  ECOMSAASAPIURL: process.env.REACT_APP_ECOMSAASASSETS,
  REDDISAPIURL: process.env.REACT_APP_REDDISAPIURL,
  MOCKUPSURL: process.env.REACT_APP_MOCKUPSURL,
  IMAGEURL: process.env.REACT_APP_IMAGEURL,
  HDIMAGEURL: process.env.REACT_APP_HDIMAGEURL,
  PRODUCTPAGEIMAGEURL: process.env.REACT_APP_PRODUCTPAGEIMAGEURL,
  PRODUCTVIEWIMAGEURL: process.env.REACT_APP_PRODUCTVIEWIMAGEURL,
  THUMBNAILSIMAGEURL: process.env.REACT_APP_THUMBNAILSIMAGEURL,

  LOGINAPI: "api/login",
  REQUESTOTPAPI: "api/requestotp",
  VERIFYOTPAPI: "api/verifyotp",
  EMAILREQUESTOTPAPI: "api/email/requestotp",
  EMAILVERIFYOTPAPI: "api/email/verifyotp",
  INSTAMOJOAPI: "api/instamojo",
  HAODAPAYUPI: "api/haodapayupi",
  RAZORPAYAPI: "api/razorpay",
  PHONEPEAPI: "api/phonepe",
  STRIPEAPI: "api/stripe",
  CODPAYAPI: "api/codpayment",

  REGISTERAPI: "api/register",
  TWOFACTORLOGINAPI: "api/twofactor/login",
  LOGOUTAPI: "api/logout",
  PROFILEAPI: "api/userprofile",
  UPDATEPROFILEAPI: "api/userprofile/update",
  SUBMITCONTACTFORMAPI: "api/submit/contact-form",
  NEWSLETTERFORMAPI: "api/newsletter/subscribe",
  PRODUCTSAPI: "api/products",
  CATEGORYAPI: "api/category",
  SUBMITDELIVERYADDRESSAPI: "api/deliveryaddress",
  FETCHDELIVERYADDRESSAPI: "api/fetchdeliveryaddress",
  CALCULATESHIPPINGCHARGEAPI: "api/calculate/shippingcharge",
  CALCULATECODCHARGEAPI: "api/calculate/codcharge",

  FBCONVERSIONPUSHAPI: `api/marketing/push-fbconversion?storeid=${STOREID}`,

  COUPONSLISTAPI: "api/coupons",
  VALIDATECOUPONSLISTAPI: "api/validate-coupon",

  WISHLISTPUSHAPI: "api/userwishlist/push",
  WISHLISTFETCHAPI: "api/userwishlist/fetch",
  WISHLISTCLEARAPI: "api/userwishlist/clear",

  PRODUCTREVIEWSPUSHAPI: "api/add/product/review",
  PRODUCTREVIEWSFETCHAPI: "api/fetch/product/review",
  PRODUCTREVIEWSCLEARAPI: "api/userwishlist/clear",
  OVERALLPRODUCTSRATINGAPI: "api/products/average/rating",

  VIEWORDERAPI: "api/vieworder",
  WALLETTRANSACTIONHISTORYAPI: "api/wallet/transaction/history",
  VIEWORDERBYIDAPI: "api/vieworderbyid",
  VIEWORDERDETAILSAPI: "api/vieworderdetails",
  TRACKORDERAPI: "api/trackorder",

  DYNAMICPAGESAPI: "api/pages",
  DYNAMICBLOGSAPI: "api/blog",

  PRODUCTSJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=products`,
  // FEATUREDPRODUCTSJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=catalog_products`,
  MOCKUP_PRODUCTSJSONAPI: `products.json`,
  CATEGORYJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=category`,
  SIZEJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=size`,
  WEIGHTJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=weight`,
  VARIATIONSJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=variations`,
  VERSIONMANAGERJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=versionmanager`,
  COLORCODESJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=colorcodes`,
  PRODUCTSCOLORCODESJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=productscolorcodes`,
  PRODUCTSSIZESJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=productssizes`,
  STORESJSONAPI: `redisapi/readData.php?storeid=${STOREID}&jsonname=stores`,
  COUNTRIESJSONAPI: `redisapi/readData.php?storeid=0&jsonname=countries`,

  eFEATUREDPRODUCTSJSONAPI: generateECOMSAASEndpoint(STORECODE, "catalog_products"),
  ePRODUCTSJSONAPI: generateECOMSAASEndpoint(STORECODE, "products"),
  eCATEGORYJSONAPI: generateECOMSAASEndpoint(STORECODE, "category"),
  eSIZEJSONAPI: generateECOMSAASEndpoint("", "size"),
  eWEIGHTJSONAPI: generateECOMSAASEndpoint("", "weight"),
  eVARIATIONSJSONAPI: generateECOMSAASEndpoint("", "variations"),
  eVERSIONMANAGERJSONAPI: generateECOMSAASEndpoint(STORECODE, "versionmanager"),
  eCOLORCODESJSONAPI: generateECOMSAASEndpoint("", "colorcodes"),
  ePRODUCTSCOLORCODESJSONAPI: generateECOMSAASEndpoint(
    STORECODE,
    "productscolorcodes"
  ),
  ePRODUCTSSIZESJSONAPI: generateECOMSAASEndpoint(STORECODE, "productssizes"),
  eSTORESJSONAPI: generateECOMSAASEndpoint(STORECODE, "stores"),
  eCOUNTRIESJSONAPI: generateECOMSAASEndpoint("", "countries"),
  eSTATESJSONAPI: generateECOMSAASEndpoint("", "states"),
  eORDERSTATUSJSONAPI: generateECOMSAASEndpoint("", "orderstatus"),

  // PRODUCTSJSONAPI: `redisapi/${STORECODE}/products.json`,
  // CATEGORYJSONAPI: `redisapi/${STORECODE}/category.json`,
  // SIZEJSONAPI: `redisapi/size.json`,
  // WEIGHTJSONAPI: `redisapi/weight.json`,
  // VARIATIONSJSONAPI: `redisapi/variations.json`,
  // VERSIONMANAGERJSONAPI: `redisapi/${STORECODE}/versionmanager.json`,
  // COLORCODESJSONAPI: `redisapi/colorcodes.json`,
  // PRODUCTSCOLORCODESJSONAPI: `redisapi/${STORECODE}/productscolorcodes.json`,
  // PRODUCTSSIZESJSONAPI: `redisapi/${STORECODE}/productssizes.json`,
  // STORESJSONAPI: `redisapi/${STORECODE}/stores.json`,
  // COUNTRIESJSONAPI: `redisapi/countries.json`,

  MOCKUP_PRODUCTSJSONAPI: `products.json`,
  MOCKUP_CATEGORYJSONAPI: `category.json`,
  MOCKUP_SIZEJSONAPI: `size.json`,
  MOCKUP_WEIGHTJSONAPI: `weight.json`,
  MOCKUP_VARIATIONSJSONAPI: `variations.json`,
  MOCKUP_VERSIONMANAGERJSONAPI: `versionmanager.json`,
  MOCKUP_COLORCODESJSONAPI: `colorcodes.json`,
  MOCKUP_PRODUCTSCOLORCODESJSONAPI: `productscolorcodes.json`,
  MOCKUP_PRODUCTSSIZESJSONAPI: `productssizes.json`,
  MOCKUP_STORESJSONAPI: `stores.json`,
  MOCKUP_COUNTRIESJSONAPI: `countries.json`,
  MOCKUP_STATESJSONAPI: `states.json`,
  MOCKUP_ORDERSTATUSJSONAPI: `orderstatus.json`,

  CATEGORY_HOMEPAGE_PRODUCTSAPI: "api/homepage/products",
  WALLETTRANSACTIONHISTORYAPI: "api/wallet/transaction/history",

  DASHBOARDAPI: "api/dashboard",
  COLLECTIONS_PAYINFORMATIONAPI: "api/collections/payin",
  BBPSCONSENTDOCSAPI: "api/bbpsconsentdocs",
  BBPSCONSENTDOCS_SUBMITAPI: "api/bbpsconsentdocs/submit",
  SETTLEMENTSAPI: "api/settlements",
  CHARGEBACKSAPI: "api/chargebacks",
  CHARGEBACKSACCEPTEDAPI: "api/chargebacks/accepted",
  INVOICESAPI: "api/invoices",
  PAYOUTSAPI: "api/payouts",
  VIEWBENEFICIARIESAPI: "api/payout/view/beneficiaries",
  MYACCOUNTAPI: "api/myaccount",
  UPDATETWOFACTORAUTHAPI: "api/update/twofactorauth",
  RAISEDISPUTESAPI: "api/payout/raise/disputes",
  ADDBENEFICIARIESAPI: "api/payout/add/beneficiaries",
  INTIATEPAYOUTREQUESTAPI: "api/payout/initiate/request",
  INTIATEPAYOUTVALIDATEDOTPAPI: "api/payout/initiate/validateOTP",
  INTIATEPAYOUTQUERYAPI: "api/payout/initiate/page",
  KYCINDEXAPI: "api/kycs",
  KYCSUBMITAPI: "api/kycs/submit",
  WALLETSUBMITAPI: "api/walletpayment",
  USERCARTINFOAPI: "api/usercartinfo/fetch",
  USERCARTINFOFETCHPUSHAPI: "api/usercartinfo/push",
  USERCARTINFOFETCHCLEARAPI: "api/usercartinfo/clear",
  SITEMAPXMLAPI: "product-feed/2",
});