export const APPENUMS = Object.freeze({
  COUPON_TYPES: {
    display: "display",
    hidden: "hidden",
  },
  COUPON_DISCOUNT_TYPES: {
    amount: "amount",
    percentage: "percentage",
    cashbackaamount: "cashbackamount",
    cashbackpercentage: "cashbackpercentage",
  },
  EVENT_ENUMS: {
    PAGE_VIEW: { id: 1, name: "PageView" },
    HOMEPAGE: { id: 2, name: "Homepage" },
    PRODUCT_LIST: { id: 3, name: "Product List" },
    PRODUCT_DETAILED: { id: 4, name: "Product Detailed" },
    ADD_TO_CART: { id: 5, name: "Add To Cart" },
    CART: { id: 6, name: "Cart" },
    CHECKOUT: { id: 7, name: "Checkout" },
    INITIATE_CHECKOUT: { id: 8, name: "Initiate Checkout" },
    SIGN_IN: { id: 9, name: "Sign In" },
    SIGN_IN_WITH_EMAIL: { id: 10, name: "Sign In With Email" },
    SIGN_UP: { id: 11, name: "Sign Up" },
    ORDER_DETAILS: { id: 12, name: "Order details" },
    ORDER_HISTORY: { id: 13, name: "Order history" },
    MY_PROFILE: { id: 14, name: "My Profile" },
    MY_ADDRESS: { id: 15, name: "My Address" },
    MY_WALLET: { id: 16, name: "My Wallet" },
    MY_WISHLIST: { id: 17, name: "My Wishlist" },
    PURCHASE: { id: 18, name: "Purchase" },
    TRACK_ORDER: { id: 19, name: "Track Order" },
    SHIPPING_AND_REFUND_POLICY: { id: 20, name: "Shipping And Refund Policy" },
    CHANGE_PASSWORD: { id: 21, name: "Change Password" },
    PROFILE_UPDATE: { id: 22, name: "Profile Update" },
    ORDER_SUCCESS: { id: 22, name: "Order Success" },
  },
});